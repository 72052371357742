var generic = generic || {};

(function ($) {
  'use strict';

  Drupal.behaviors.loyaltyEnrollPostSigninV1 = {
    attach: function (context, settings) {
      var self = this;

      if (document.location.pathname == '/account/registration_pro.tmpl') {
        $.cookie('LOYALTY_POPUP', null, { path: '/' });

        return;
      }
      // Set cookie while login to myaccount to show the loyalty popup
      $('.js-registration-submit, .js-signin-block-register', context).on('click', function (e) {
        $.cookie('LOYALTY_POPUP', '1', {
          path: '/',
          expires: 60
        });
      });

      // To handle the loyalty enroll events for different popup overlay
      self.loyaltyEventHandlers();

      if (!$.cookie('LOYALTY_POPUP')) {
        return;
      }
      // Check the url is account page if not hide the overlay
      if (document.location.pathname != '/account/index.tmpl') {
        return;
      }

      $(document).on('user.loaded', function (event, val) {
        // Get the mobile client value to be used for rendering the content separately
        var isMobile = site.client.isMobile;

        // Condition to show the loyalty popup if the logged in user is not enrolled for loyalty before
        if (
          document.location.pathname != '/account/registration_pro.tmpl' &&
          !val.is_pro &&
          !val.is_pro_pending &&
          val.signed_in &&
          val.is_loyalty_member === 0 &&
          val.disable_display_loyalty_popup === 0
        ) {
          // Fetching the mustache template content
          var rendered = site.template.get({
            name: 'loyalty_enroll_post_signin_v1'
          });

          // Rendering the content in mobile and pc separately
          if (isMobile) {
            $('.mobile-account-page', context).html(rendered);
          } else {
            $('.signin-block__authed', context).addClass('hidden');
            $('.gnav-util--account', context).addClass('post-signin-smashcash');
            // Appending the content in signin_block_v1.mustache
            $('.js-loyalty-join-content', context).html(rendered).removeClass('hidden');
            $('<div class="content-dim"/>').insertAfter('#cboxOverlay');
            $('.gnav-util__content-close', context).attr('id', 'js-cbox-close').removeClass('js-gnav-util-close');
            $('.gnav-util__link--account', context).trigger('click');
          }
        }
      });
    },
    /**
     * To Handle the Events on two on different popup context
     */
    loyaltyEventHandlers: function () {
      // Get the mobile client value to be used for rendering the content separately
      var isMobile = site.client.isMobile;

      // Form submission taking place over here
      $(document).on('click', '.js-loyalty-join', function (e) {
        e.preventDefault();
        var $loyaltyJoinBlock = $(this).closest('.loyalty-join');
        var context = $loyaltyJoinBlock;
        var paramObj = {};
        var inputVals = $loyaltyJoinBlock.find('#js-loyalty-form').serializeArray();

        // loop through all the fields and get the values
        $.each(inputVals, function (index, formObj) {
          paramObj[formObj.name] = formObj.value;
        });
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (r) {
            var messages = r.getMessages();

            if (messages.length > 1) {
              $('.js-loyalty-err-msg', context).empty().html(messages[0].text).removeClass('hidden');
            } else {
              $('.js-loyalty-err-msg', context).empty().addClass('hidden');
              // Removing the loyalty popup cookie once the user gets enrolled
              $.cookie('LOYALTY_POPUP', null, { path: '/' });
              location.reload();
            }
          },
          onFailure: function (r) {
            var messages = r.getMessages();

            $('.js-loyalty-err-msg', context).empty().html(messages[0].text).removeClass('hidden');
          }
        });
      });

      // Closing the loyalty popup
      $(document).on(
        'click',
        '.js-loyalty-cbox-close, #js-cbox-close, .js-loyalty-box-close, .js-loyalty-box-close-signin',
        function (e) {
          e.preventDefault();
          var $loyaltySignInBlock = $('.js-signin-block');
          var $loyaltyCloseBlock = $('.gnav-util-formatter');

          // Deleting the cookie if the user is not enrolled for loyalty
          $.cookie('LOYALTY_POPUP', null, { path: '/' });
          if (isMobile) {
            location.reload();
          } else {
            $loyaltySignInBlock.find('.js-loyalty-join-content').addClass('hidden');
            $('.gnav-util__content-close').addClass('js-gnav-util-close').removeAttr('id');
            if ($loyaltyCloseBlock.find('.gnav-util--account').hasClass('post-signin-smashcash')) {
              $loyaltyCloseBlock.find('.gnav-util--account').removeClass('post-signin-smashcash');
            }
            $loyaltySignInBlock.find('.signin-block__authed').removeClass('hidden');
            // For reset the loyalty overlay width for Gnav overlay
            $loyaltyCloseBlock
              .find('.js-gnav-util__content')
              .removeClass('gnav-util__content--loyalty-join')
              .addClass('gnav-util__content--account');
            $loyaltyCloseBlock
              .find('.js-gnav-util')
              .removeClass('gnav-util--loyalty-join')
              .addClass('gnav-util--account');
            $loyaltyCloseBlock.find('.js-gnav-util-close').trigger('click');
          }
        }
      );
    }
  };
})(jQuery);
